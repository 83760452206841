.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 76px;
  display: flex;
  font-size: x-large;
  color: white;
  font-weight: 700;
}

.center {
  align-items: center;
  justify-content: center;
}

.App-link {
  color: #61dafb;
}

.side-padding-10vw {
  padding-left: 10vw;
  padding-right: 10vw;
}

.column-holder {
  display: flex;
}

.column {
  flex: 1;
}

.vertical-center {
  display: flex;
  align-items: center;
}

.align-right {
  display: flex;
  justify-content: right;
}

.padding-right {
  padding-right: 1em;
}

.padding-top {
  padding-top: 1em;
}

.full-size {
  width: 100vw;
}

.App-Body {
  height: calc(100vh - 76px);
  padding-top: 1em;
}

.overflow-y {
  overflow-y: scroll;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 27px;
  left: 24px;
  top: 24px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: white;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: white;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 30px;
  width: 30px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: larger;
  font-weight: 700;
}

.bm-item:hover {
  color: #c94e50;
}

.bm-item:focus {
  color: #c94e50;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
