.center {
  align-items: center;
  justify-content: center;
}

.side-padding-5vw {
  padding-left: 5vw;
  padding-right: 5vw;
}

.side-padding-10vw {
  padding-left: 10vw;
  padding-right: 10vw;
}

.column-holder {
  display: flex;
}

.column {
  flex: 1;
}

.horizontal-scroll {
  display: flex;
  direction: ltr;
  overflow-x: scroll;
}

.side-padding-1em {
  padding-left: 1em;
  padding-right: 1em;
}

.top-bottom-padding-1em {
  padding-top: 1em;
  padding-bottom: 1em;
}

.vertical-center {
  display: flex;
  align-items: center;
}

.padding-top {
  padding-top: 1em;
}

.padding-right {
  padding-right: 1em;
}

.margin-top {
  margin-top: 1em;
}

.margin-top-2em {
  margin-top: 2em;
}

.margin-bottom {
  margin-bottom: 1em;
}

.margin-bottom-3em {
  margin-bottom: 3em;
}

.side-margins-2em {
  margin-left: 2em;
  margin-right: 2em;
}

.bottom-border {
  border-bottom: .5px solid lightslategray
}

.align-right {
  display: flex;
  justify-content: right;
}

.align-left {
  display: flex;
  align-items: center;
  justify-content: left;
}
