.center {
  align-items: center;
  justify-content: center;
}

.all-tabs {
  margin-top: .5em;
  justify-content: center;
}

.active-tr {
  background-color: lightgray;
}
