.center {
  align-items: center;
  justify-content: center;
}

.side-padding-10vw {
  padding-left: 10vw;
  padding-right: 10vw;
}

.column-holder {
  display: flex;
}

.column {
  flex: 1;
}

.column-3 {
  flex: 3;
}

.vertical-center {
  display: flex;
  align-items: center;
}

.padding-top-2em {
  padding-top: 2em;
}

.padding-top {
  padding-top: 1em;
}

.padding-bottom {
  padding-bottom: 1em;
}

.padding-left {
  padding-left: 1em;
}

.padding-right {
  padding-right: 1em;
}

.margin-top {
  margin-top: 1em;
}

.min-width-250 {
  min-width: 250px;
}

.align-left {
  display: flex;
  justify-content: left;
}

.align-right {
  display: flex;
  justify-content: right;
}
