.center {
  align-items: center;
  justify-content: center;
}

.horizontal-center {
  display: flex;
  justify-content: center;
}

.vertical-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.marginTopBottom-3em {
  margin-top: 3em;
  margin-bottom: 3em;
}

.marginBottom-1em {
  margin-bottom: 1em;
}

.marginSides-1em {
  margin-left: 1em;
  margin-right: 1em;
}

.marginSides-3em {
  margin-left: 3em;
  margin-right: 3em;
}

.party-play-title {
  font-size: 34px;
}

.game-title {
  font-size: 26px;
  font-weight: 500;
}

.game-rules {
  font-size: 20px;
}
