.center {
  align-items: center;
  justify-content: center;
}

.side-padding-10vw {
  padding-left: 10vw;
  padding-right: 10vw;
}

.horizontal-flex {
  display: flex;
  direction: ltr;
}

.column-holder {
  display: flex;
}

.column {
  flex: 1;
}

.column2 {
  flex: 2;
}

.vertical-center {
  display: flex;
  align-items: center;
}

.padding-top {
  padding-top: 1em;
}

.padding-bottom-2em {
  padding-bottom: 2em;
}

.padding-bottom {
  padding-bottom: 1em;
}

.padding-top-half {
  padding-top: 0.5em;
}

.padding-left {
  padding-left: 1em;
}

.padding-right {
  padding-right: 1em;
}

.align-right {
  display: flex;
  justify-content: right;
}
.margin-left {
  margin-left: 1em;
}

.align-left {
  text-align: left;
}
