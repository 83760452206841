.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

.side-padding-10vw {
  padding-left: 10vw;
  padding-right: 10vw;
}

.column-holder {
  display: flex;
}

.column {
  flex: 1;
}

.vertical-center {
  display: flex;
  align-items: center;
}

.align-right {
  display: flex;
  justify-content: right;
}

.padding-right {
  padding-right: 1em;
}

.padding-top {
  padding-top: 1em;
}
